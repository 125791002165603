<div class="property-not-found ios-padding-top ios-padding-bottom">
  <div class="property-not-found__content">
    <app-image
      class="property-not-found__image"
      [defaultSrc]="error.image"
    ></app-image>
    <div class="property-not-found__header title-xxl">
      {{error.title | translate }}
    </div>
    <p class="property-not-found__text">{{error.description | translate}}</p>
    <p *ngIf="error?.extraText" class="property-not-found__text">
      {{error.extraText | translate}}
    </p>
    <app-button
      *ngIf="error.showButton"
      [type]="'light-bordered'"
      [iconLeft]="'reset'"
      class="property-not-found__button"
      (clickEvent)="onGoBack()"
      >{{error.linkLabel | translate}}
    </app-button>
  </div>
</div>
