import { Component } from '@angular/core';
import { MainPageNavigation } from 'tenant-pool/config/navigation.config';
import { Store } from '@ngrx/store';
import * as fromBaseState from 'libs/infrastructure/base-state';

const DEFAULT_ERROR = {
  title: 'properties.not_found.error_title_l',
  description: 'properties.not_found.error_description_m',
  image: '/assets/images/Error_2.svg',
  extraText: '',
  showButton: true,
  linkLabel: 'general.back_to_app_l',
  link: MainPageNavigation.APPLICATIONS
};

@Component({
  selector: 'app-property-not-found',
  templateUrl: './property-not-found.component.html',
  styleUrls: ['./property-not-found.component.scss']
})
export class PropertyNotFoundComponent {
  constructor(private store: Store<fromBaseState.BaseState>) {}
  public error = DEFAULT_ERROR;

  onGoBack() {
    this.store.dispatch(
      new fromBaseState.Go({
        path: [this.error.link]
      })
    );
  }
}
